<template>
  <div class="userinfoView">
    <van-nav-bar :title="$t('详细信息')" left-arrow @click-left="onClickLeft" />

    <div class="content_box text-center">
      <van-cell-group>
        <van-field v-model="realname" :label="$t('真实姓名')" :disabled="disabled" :placeholder="$t('请输入您的真实姓名')" />
      </van-cell-group>
      <van-button type="primary" class="big_other_btn2" @click="submitHandler">{{ $t('提交资料') }}</van-button>
    </div>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from '@/utils/local-storage';
import { set_userinfo } from '@/api/users';
import area from '@/utils/area';
export default {
  name: 'login',
  data() {
    return {
      disabled: this.$store.getters.userinfo.realname ? true : false,
      realname: this.$store.getters.userinfo.realname,
    };
  },
  created() {
    this.areaList = area;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      if (this.disabled == true) {
        this.$toast({
          message: this.$t('已设置'),
          duration: 1000,
        });
        return;
      }
      const { realname } = this;
      let field = { realname };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some((key) => {
        let validInfo = this.$validate['valid' + key.charAt(0).toUpperCase() + key.slice(1)](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000,
          });
          return true;
        } else {
          return false;
        }
      });
      if (!objSome) {
        set_userinfo(field)
          .then((response) => {
            this.$toast({
              message: response.msg,
              duration: 500,
              forbidClick: true,
            });
            this.disabled = true;
          })
          .catch((error) => {});
      }
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.userinfoView
  .content_box
    margin: 0.3rem
    .mobile
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    h2
      color: $grayTextColor
    .van-cell
      background-color: $cellBackColor
      padding: 0.1rem 0.3rem
    .van-icon::before
      color: $grayTextColor
    .van-icon
      transform: rotate(90deg)
    .big_btn
      margin: 0.36rem 0 0 0
</style>
